import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'toggleNav', 'closeNav', 'nav', 'screenSize' ];

  toggleNav() {
    if (this.navTarget.classList.contains('col-3')) {
      this.closeNav()
    } else {
      this.navTarget.className = 'col-3';
      this.screenSizeTarget.className = 'background-library col-9 p-0';
    }
  }

  closeNav() {
    this.navTarget.classList.add('d-none');
    this.navTarget.classList.remove('col-3');
    this.screenSizeTarget.className = 'background-library col-12 p-0';
  }
}
