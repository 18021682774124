import { Controller } from 'stimulus';
import { initAutosize } from './../packs/components/init_autosize';
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  static targets = [ 'add', 'addForm', 'menu', 'updateForm', 'reminder', 'bell', 'input' ];

  connect() {
    smoothscroll.polyfill();
  }

  toggleEditForm(event) {
    this.updateFormTarget.classList.toggle('d-none');
  }

  toggleNewForm(event) {
    this.reminderTargets.forEach((reminder) => reminder.classList.toggle('d-none'));
    this.addTarget.classList.toggle('d-none');
    this.addTarget.classList.toggle('d-flex');
    this.addFormTarget.classList.toggle('d-none');
    this.inputTargets.forEach((input) => initAutosize(input));
  }

  toggleBell(event) {
    this.bellTarget.classList.toggle('fa-bell');
    this.bellTarget.classList.toggle('text-primary');
    this.bellTarget.classList.toggle('fa-bell-slash');
    this.bellTarget.classList.toggle('text-lg');
  }

  switchTab() {
    this.reminderTargets.forEach((reminder) => reminder.classList.remove('d-none'));
    this.addTarget.classList.remove('d-none');
    this.addTarget.classList.add('d-flex');
    this.addFormTarget.classList.add('d-none');
  }
}
