import { Controller } from 'stimulus';
import { initAutosize, updateSize } from './../packs/components/init_autosize';

export default class extends Controller {
  static targets = [ 'input' ];
  connect() {
    initAutosize(this.inputTarget);
  }

  updateSize() {
    updateSize(this.inputTarget);
  }
}
