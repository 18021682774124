import tippy from 'tippy.js';

const initTippy = () => {
  document.querySelectorAll('[data-tippy]').forEach((element) => {
    const placement = element.dataset.placement ? element.dataset.placement : 'top';
    const trigger = element.dataset.trigger ? element.dataset.trigger : 'mouseenter focus';
    const interactive = element.dataset.interactive ? true : false;
    tippy(element, {
      content: element.dataset.tippy,
      theme: 'light-border',
      placement,
      animation: 'scale-extreme',
      allowHTML: true,
      trigger,
      interactive,
      inertia: true
    });
  });
};

const initTippyAjax = () => {
  document.querySelectorAll('[data-tippy-ajax]').forEach((element) => {
    const placement = element.dataset.placement ? element.dataset.placement : 'top';
    const trigger = element.dataset.trigger ? element.dataset.trigger : 'mouseenter focus';
    const interactive = element.dataset.interactive ? true : false;
    tippy(element, {
      content: element.dataset.tippyAjax,
      theme: 'light-border',
      placement,
      animation: 'scale-extreme',
      allowHTML: true,
      trigger,
      interactive,
      inertia: true,
      onShow(instance) {
        fetch('/api/v1/notifications')
        .then(response => response.json())
        .then(data => instance.setContent(data.notifications));
      }
    });
  });
}

export { initTippy, initTippyAjax };
