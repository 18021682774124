import { Controller } from 'stimulus';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = [ 'cta', 'price', 'period', 'form', 'pay', 'loader', 'card', 'mainCard' ];

  toggleCard() {
    this.cardTargets.forEach(card => card.classList.remove('pricing-active'))
    event.currentTarget.classList.add('pricing-active')
  }

  mainCard() {
    this.cardTargets.forEach(card => card.classList.remove('pricing-active'))
    this.mainCardTarget.classList.add('pricing-active')
  }

  togglePeriod(event) {
    event.preventDefault();
    const ctaText = this.ctaTarget.innerText;
    this.ctaTarget.innerText = this.ctaTarget.dataset.text;
    this.ctaTarget.setAttribute('data-text', ctaText);
    this.priceTargets.forEach((target) => {
      const targetPrice = target.innerText;
      target.innerText = target.dataset.price;
      target.setAttribute('data-price', targetPrice);
    });
    this.periodTargets.forEach((target) => {
      const period = target.innerText;
      target.innerText = target.dataset.period;
      target.setAttribute('data-period', period);
    });
    this.formTargets.forEach((target) => {
      const url = target.action;
      target.action = target.dataset.url;
      target.setAttribute('data-url', url);
    });
    const url = `${window.location.pathname}${window.location.search}`;
    history.pushState({
      id: 'pricing'
    }, 'KROWL - Conversation', this.data.get('url'));
    this.data.set('url', url);
  }

  async pay() {
    this.loaderTarget.classList.remove('d-none');
    this.payTarget.disabled = true;
    const stripe = await loadStripe(this.data.get('stripePublicKey'));
    const result = stripe.redirectToCheckout({
      sessionId: this.data.get('session')
    });

    if (result.error) {
      Swal.fire({
        title: 'An error occured',
        text: result.error.message,
        icon: 'error',
        confirmButtonText: 'Go back and try again',
        confirmButtonColor: '#004495',
        showCancelButton: false
      }).then((result) => {
        if (result.value) {
          window.location.href = `/subscription${window.location.search}`;
        }
      });
    }
  }
}
