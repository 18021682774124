require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");
require('local-time').start();
require('jquery');
require("bootstrap-datepicker");

import "bootstrap";
import "controllers";
import AOS from 'aos';
import Appsignal from "@appsignal/javascript";
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/scale-extreme.css';
import '@mdi/font/css/materialdesignicons.css';
import 'select2/dist/css/select2.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/dist/tippy.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'aos/dist/aos.css';
import 'sweetalert2/src/sweetalert2.scss';
import 'cropperjs/dist/cropper.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import './components/trix_editor_overrides';
import { requestPermission } from './helpers/web_notifications';
import { initTippy, initTippyAjax } from './components/init_tippy';
import { inviteOccupant, removeOccupant } from './components/init_occupants';

document.addEventListener('turbolinks:load', () => {
  const appsignal = new Appsignal({
    key: "4a93f0ae-3e69-4802-a544-bec2f8f7e75b",
  });
  requestPermission();
  initTippy();
  initTippyAjax();
  AOS.init();
  inviteOccupant();
  removeOccupant();
});
