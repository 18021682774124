import { Controller } from 'stimulus';
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  static targets = [ 'free', 'plus', 'pro' ];

  connect() {
    smoothscroll.polyfill();
  }

  smooth_scroll(event) {
    event.preventDefault();
    document.querySelector(event.target.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
    });
  }
}
