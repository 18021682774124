import { Controller } from 'stimulus';
import Swal from 'sweetalert2';
const OT = require('@opentok/client');

export default class extends Controller {

  static targets = [ 'startShare', 'stopShare', 'streamMenu', 'showArrow', 'hideArrow', 'buttons',
                     'toggleAudio', 'toggleVideo', 'container', 'screenShare', 'videoContainer',
                     'screenShareContainer', 'currentShare', 'audioLevel' ];

  static values = {
    audio: Boolean,
    video: Boolean,
    silent: Boolean
  };

  static classes = [ 'sideVideo' ];

  connect() {
    this.initializeSession();
  }

  disconnect() {
    if (this.session) this.session.disconnect();
  }

  initializeSession() {
    this.setSession();
    this.session.on('streamCreated', this.streamCreated.bind(this));
    this.session.on('streamDestroyed', this.setScreenShareDimensions.bind(this));
    this.session.on('sessionDisconnected', this.setScreenShareDimensions.bind(this));
    this.session.on('connectionDestroyed', this.setScreenShareDimensions.bind(this));
    this.session.connect(this.token, this.streamConnected.bind(this));
    this.session.on('connectionCreated', this.setScreenShareDimensions.bind(this))
    this.session.on("streamPropertyChanged", this.streamPropertyChanged.bind(this));
    this.session.on("switchaudiosource", this.streamPropertyChanged.bind(this.publisher));
  }

  setSession() {
    this.session = OT.initSession(this.apiKey, this.sessionId);
  }

  streamConnected(error) {
    this.setPublisher();
    error ? this.handleError(error) : this.session.publish(this.publisher, this.handleError.bind(this));
  }

  streamCreated(event) {
    const div = document.createElement('div');
    if (event.stream.videoType === 'screen') {
      div.className = 'flex-grow-1 h-100';
      div.setAttribute(`data-${this.identifier}-target`, 'screenShareContainer');
      this.screenShareTarget.appendChild(div);
      this.screenShareTarget.insertBefore(div, this.screenShareTarget.firstChild);
      this.startShareTarget.classList.add('d-none');
    } else {
      div.className = 'stream-wrapper border-transparent';
      div.setAttribute(`data-${this.identifier}-target`, 'videoContainer');
      this.containerTarget.appendChild(div);
    }
    const subscriber = this.session.subscribe(event.stream, div, {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
    }, this.handleError.bind(this));
    div.setAttribute('data-id', subscriber.stream.streamId.trim());
    subscriber.on('disconnected', this.setScreenShareDimensions.bind(this));
    subscriber.on('destroyed', this.setScreenShareDimensions.bind(this));
    this.setScreenShareDimensions(event);
    if (this.silentValue) this.subscribers(event).forEach(subscriber => this.setSilentImage(subscriber));
    this.highlightSpeaker(subscriber, subscriber.element.parentElement);
    this.switchaudiosource(this.publisher);
  }

  setPublisher() {
    const div = document.createElement('div');
    div.className = 'stream-wrapper border-transparent';
    div.setAttribute (`data-${this.identifier}-target`, 'videoContainer');
    this.containerTarget.appendChild(div);
    this.containerTarget.insertBefore(div, this.containerTarget.firstChild);
    this.publisher = OT.initPublisher(div,
    {
      insertMode: 'prepend',
      width: '100%',
      height: '100%',
      showControls: false,
      usePreviousDeviceSelection: true,
      name: this.data.get('name'),
      publishAudio: this.audioValue
    }, this.handleError.bind(this));
    if (this.silentValue) this.setSilentImage(this.publisher);
    this.highlightSpeaker(this.publisher, this.publisher.element);
    this.adjustMicrophone(this.publisher);
    this.switchaudiosource(this.publisher);
  }

  setScreenPublisher() {
    const div = document.createElement('div');
    div.className = 'flex-grow-1 h-100 js-screenshare-container';
    this.screenShareTarget.appendChild(div);
    const innerDiv = document.createElement('div');
    div.appendChild(innerDiv);
    this.screenShareTarget.insertBefore(div, this.screenShareTarget.firstChild);
    this.screenPublisher = OT.initPublisher(innerDiv,
      {
        insertMode: 'prepend',
        width: '100%',
        height: '100%',
        margin: 'auto',
        showControls: false,
        videoSource: 'screen',
        usePreviousDeviceSelection: true,
        name: `${this.data.get('name')}'s screen`
      }, this.handleError.bind(this));
    this.screenPublisher.on('mediaStopped', this.stopScreenSharing.bind(this));
    this.screenPublisher.on('streamDestroyed', this.stopScreenSharing.bind(this));
  }

  adjustMicrophone(subscriber) {
    const audioLevel = this.audioLevelTarget
    let movingAvg = null;
    subscriber.on('audioLevelUpdated', function(event) {
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }
      let logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      audioLevel.style.height = `${Math.floor(logLevel * 100)}%`;
    });
  }

  highlightSpeaker(subscriber, targetElement) {
    this.speakerDetection(subscriber, function() {
      targetElement.classList.add('speaking');
    }, function() {
      targetElement.classList.remove('speaking');
    });
  }

  switchaudiosource(subscriber){
    let audioInputs;
    let currentIndex = 0;
    OT.getUserMedia({
        videoSource: null
      }).then((stream) => {
        const [audioSource] = stream.getAudioTracks();
        this.publisher.setAudioSource(audioSource).then(() => console.log('Audio source updated'));

        OT.getDevices((err, devices) => {
          audioInputs = devices.filter((device) => device.kind === 'audioInput');
          // Find the right starting index for cycleMicrophone
          audioInputs.forEach((device, idx) => {
            if (device.label === this.publisher.getAudioSource().label) {
              currentIndex = idx;
            }
          });
          this.setAudioSetting(audioInputs);
        });
      const cycleMicrophone = () => {
        currentIndex += 1;
        let deviceId = audioInputs[currentIndex % audioInputs.length].deviceId;
        this.publisher.setAudioSource(deviceId);
      };
    });
  }

  speakerDetection(subscriber, startTalking, stopTalking) {
    let activity = null;
    subscriber.on('audioLevelUpdated', function(event) {
      const now = Date.now();
      if (event.audioLevel > 0.2) {
        if (!activity) {
          activity = { timestamp: now, talking: false };
        } else if (activity.talking) {
          activity.timestamp = now;
        } else if (now - activity.timestamp > 500) {
          // detected audio activity for more than half a second
          // for the first time.
          activity.talking = true;
          if (typeof(startTalking) === 'function') {
            startTalking();
          }
        }
      } else if (activity && now - activity.timestamp > 2000) {
        // detected low audio activity for more than 2s
        if (activity.talking) {
          if (typeof(stopTalking) === 'function') {
            stopTalking();
          }
        }
        activity = null;
      }
    });
  }

  streamPropertyChanged(event) {
    const subscriber = this.subscribers(event).find(subscriber => subscriber.stream.id === event.stream.id);
    if (subscriber) {
      var microphoneIcon = subscriber.element.querySelector('img');
      switch (event.changedProperty) {
        case 'hasVideo':
          if (event.newValue) {
            const imgData = subscriber.getImgData();
            subscriber.setStyle('backgroundImageURI', imgData);
          } else {
            subscriber.setStyle('backgroundImageURI',
              'https://tokbox.com/img/styleguide/tb-colors-cream.png'
            );
          }
          break;
        case 'hasAudio':
          if (event.newValue) {
            if (microphoneIcon) microphoneIcon.remove();
          } else {
            this.setSilentImage(subscriber);
          }
          break;
      }
    }
    var microphoneIcon = this.publisher.element.querySelector('img');
    if (this.publisher.stream.hasAudio) {
      if (microphoneIcon) microphoneIcon.remove();
    } else {
      this.setSilentImage(this.publisher);
    }
  }

  async start(event) {
    if (this.screenIsShared(event)) {
      return Swal.fire({
        title: 'Hello there!',
        text: 'Someone is already sharing their screen',
        icon: 'info',
        confirmButtonText: 'OK',
        confirmButtonColor: '#004495',
        showCancelButton: false
      });
    }
    OT.checkScreenSharingCapability(async (response) => {
      if (!response.supported || response.extensionRegistered === false) {
        alert('Screen sharing not supported');
      } else if (response.extensionInstalled === false) {
        alert('Browser requires extension');
      } else {
        const displayMediaOptions = {
          video: {
            cursor: 'always'
          },
          audio: true
        };
        try {
          await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
          this.startShareTarget.classList.add("d-none");
          this.stopShareTarget.classList.remove('d-none');
          this.setScreenPublisher();
          this.setScreenShareDimensions(event);
          this.stopShareTarget.id = this.screenPublisher.id;
          this.session.publish(this.screenPublisher, this.handleError.bind(this));
        } catch(err) {
          this.startShareTarget.classList.remove("d-none");
          this.stopShareTarget.classList.add('d-none');
          this.setScreenShareDimensions(event);
        }
      }
    });
  }

  stop(event) {
    this.session.unpublish(this.screenPublisher);
  }

  handleError(error) {
    error && console.error(error.message);
  }

  toggleAudio() {
    this.audioValue = !this.audioValue;
    this.publisher.publishAudio(this.audioValue);
    if (this.audioValue) {
      this.toggleAudioTarget.className = 'fas fa-microphone';
    } else {
      this.toggleAudioTarget.className = 'fas fa-microphone-slash';
    }
  }

  toggleVideo() {
    this.videoValue = !this.videoValue;
    this.publisher.publishVideo(this.videoValue);
    if (this.videoValue) {
      this.toggleVideoTarget.className = 'fas fa-video';
    } else {
      this.toggleVideoTarget.className = 'fas fa-video-slash';
    }
    if (!this.publisher.stream?.hasAudio) this.setSilentImage(this.publisher);
  }

  AudioSourceChange(event){
    let device_id = event.target.dataset.deviceid;
    this.publisher.setAudioSource(device_id);
    let microLable = document.querySelector('.microphone-audio .device span');
    microLable.innerHTML = event.target.dataset.value;
  }

  AudioSourceSpeakerChange(event){
    let device_id = event.target.dataset.deviceid;
    this.publisher.setAudioSource(device_id);
    let speakerLable = document.querySelector('.speaker-audio .device span');
    speakerLable.innerHTML = event.target.dataset.value;
  }

  setSilentImage(streamer) {
    let microphoneIcon = document.createElement('img')
    microphoneIcon.src = this.data.get('microphone-off');
    streamer.element.appendChild(microphoneIcon);
  }

  setAudioSetting(event){
    const micro = document.querySelector('.microphone-audio .select-device');
    let ul_micro = document.createElement('ul');
    micro.appendChild(ul_micro);
    event.forEach((device, idx) => {
      let li = document.createElement('li');
      li.append(device.label);
      li.setAttribute("data-value", device.label);
      li.setAttribute("data-deviceId", device.deviceId);
      li.setAttribute("data-action", "click->vonage#AudioSourceChange");
      ul_micro.appendChild(li);
    });

    const speaker = document.querySelector('.speaker-audio .select-device');
    let ul_speaker = document.createElement('ul');
    speaker.appendChild(ul_speaker);
    event.forEach((device, idx) => {
      let li = document.createElement('li');
      li.append(device.label);
      li.setAttribute("data-value", device.label);
      li.setAttribute("data-deviceId", device.deviceId);
      li.setAttribute("data-action", "click->vonage#AudioSourceSpeakerChange");
      ul_speaker.appendChild(li);
    });
  }

  subscribers(event) {
    return event?.stream ? this.session.getSubscribersForStream(event.stream) : [];
  }

  stopScreenSharing(event) {
    this.startShareTarget.classList.remove("d-none");
    this.stopShareTarget.classList.add('d-none');
    this.currentShareTarget.classList.add("d-none");
    this.screenPublisher = null;
    const screenShareContainer = document.querySelector('.js-screenshare-container');
    if (screenShareContainer) screenShareContainer.remove();
    this.setScreenShareDimensions(event);
  }

  setScreenShareDimensions(event) {
    this.screenShareTarget.classList.toggle('col-0', !this.screenIsShared(event));
    this.screenShareTarget.classList.toggle('col-12', this.screenIsShared(event));
    this.screenShareTarget.classList.toggle('col-md-8', this.screenIsShared(event));
    this.containerTarget.classList.toggle('col-12', !this.screenIsShared(event));
    this.containerTarget.classList.toggle('col-md-4', this.screenIsShared(event));
    this.containerTarget.classList.toggle('screen-shared', this.screenIsShared(event));
    this.videoContainerTargets.forEach(target => target.classList.toggle('full-width', this.screenIsShared(event)));
    if (event?.stream?.videoType === 'screen') {
      this.startShareTarget.classList.toggle('d-none', this.subscriberIsSharing(event));
      this.currentShareTarget.classList.toggle('d-none', !this.subscriberIsSharing(event));
    }
    if (event.type === 'streamDestroyed') {
      const subscriberContainer = document.querySelector(`[data-id="${event.stream.id.trim()}"]`);
      if (subscriberContainer) subscriberContainer.remove();
      this.startShareTarget.classList.remove('d-none');
      this.currentShareTarget.classList.add('d-none');
    }
  }

  screenIsShared(event) {
    const subscriber = this.subscribers(event).find(subscriber => subscriber.stream.videoType === 'screen');
    return subscriber || this.screenPublisher ? true : false;
  }

  subscriberIsSharing(event) {
    const subscriber = this.subscribers(event).find(subscriber => subscriber.stream.videoType === 'screen');
    return subscriber ? true : false;
  }

  get apiKey() {
    return this.data.get('apiKey');
  }

  get sessionId() {
    return this.data.get('sessionId');
  }

  get token() {
    return this.data.get('token');
  }
}
