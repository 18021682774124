import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "commentForm", "container", "count" ]

  submitComment(event) {
    const xhr = event.detail[2];
    const response = JSON.parse(xhr.response);
    if (response.comment) {
      this.containerTarget.insertAdjacentHTML('afterbegin', response.comment);
      const count = +this.countTarget.dataset.count + 1;
      this.countTarget.innerHTML = `Replies (${count})`;
    }
    this.commentFormTarget.outerHTML = response.form;
  }
}
