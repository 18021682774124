import consumer from './consumer';
import { currentUser } from './../packs/helpers/current_user_helper';
import { scrollToBottom, insertStrike, addUnreadMessage, insertMessage, addLastMessage, tableMessageNotification } from './../packs/helpers/messages';

const broadcastMessage = (channel) => {
  let broadcast = false;
  let table = false;
  const messagesContainer = document.querySelector('#messages');
  if (messagesContainer) {
    table = messagesContainer.dataset.table;
    broadcast = (channel === 'ConversationChannel' && !table) || (channel === 'TableConversationChannel' && table)
  }
  if (broadcast) {
    const conversationId = messagesContainer.dataset.id;
    consumer.subscriptions.create({ channel: channel, id: conversationId }, {
      received(data) {
        const parsedData = JSON.parse(data)
        const id = +messagesContainer.dataset.id;
        if (id === parsedData.conversation_id) {
          insertStrike(messagesContainer, parsedData);
          insertMessage(messagesContainer, parsedData);
          scrollToBottom(messagesContainer, currentUser(), parsedData);
        }
        addUnreadMessage(parsedData);
        addLastMessage(parsedData);
        if (table) tableMessageNotification(parsedData);
      }
    });
  }
};

document.addEventListener('turbolinks:load', () => {
  broadcastMessage('ConversationChannel');
})

export { broadcastMessage };
