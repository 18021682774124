import consumer from "./consumer";
import { initTippy } from './../packs/components/init_tippy';

const fetchFloorplan = (tablesContainer) => {
  fetch(`/workspaces/floorplan?pages=${tablesContainer.dataset.pages}`, { headers: { accept: 'application/json' } })
  .then((response) => response.json())
  .then((json) => {
    if (json.entries) tablesContainer.innerHTML = json.entries;
    initTippy();
  });
}

document.addEventListener('turbolinks:load', () => {
  const tablesContainer = document.getElementById('tables');
  if (tablesContainer) {
    fetchFloorplan(tablesContainer);
    consumer.subscriptions.create({ channel: "LibraryChannel" }, {
      received(data) {
        fetchFloorplan(tablesContainer);
      }
    });
  }
});
