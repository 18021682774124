import { currentUser } from './current_user_helper';

const scrollToBottom = (messagesContainer, user_id, parsedData) => {
  const lastMessage = messagesContainer.lastElementChild;
  if (lastMessage) {
    if (user_id && user_id != parsedData.user_id) {
      lastMessage.classList.remove('justify-content-end');
      lastMessage.classList.add('justify-content-start');
    }
    const lastMessageStyles = getComputedStyle(lastMessage);
    const lastMessageMargin = parseInt(lastMessageStyles.marginBottom) + parseInt(lastMessageStyles.marginTop);
    let lastMessageHeight = lastMessage.offsetHeight + lastMessageMargin;
    const strike = document.querySelector('.strike');
    if (strike) {
      lastMessageHeight += strike.offsetHeight;
    }
    const currentDate = document.querySelector(`[data-value="${parsedData.date}"]`);
    if (currentDate) {
      const currentDateStyles = getComputedStyle(currentDate.parentElement);
      const currentDateMargins = parseInt(currentDateStyles.marginBottom) + parseInt(currentDateStyles.marginTop);
      lastMessageHeight += currentDate.parentElement.offsetHeight + currentDateMargins;
    }
    const visibleMessagesHeight = messagesContainer.offsetHeight;
    const totalMessagesHeight = messagesContainer.scrollHeight;
    const scrollOffset = messagesContainer.scrollTop + visibleMessagesHeight;
    if (totalMessagesHeight - lastMessageHeight <= scrollOffset) {
      messagesContainer.scroll({ top: messagesContainer.scrollHeight, behavior: 'smooth' });
    }
  }
};

const insertStrike = (messagesContainer, parsedData) => {
  const strike = document.querySelector('.strike');
  if (currentUser() !== parsedData.user_id && !strike) {
    messagesContainer.insertAdjacentHTML('beforeend', parsedData.strike);
  }
};

const addUnreadMessage = (parsedData) => {
  const conversationUnreads = document.querySelector(`#conversation_unread_${parsedData.conversation_id}`);
  if (conversationUnreads && currentUser() != parsedData.user_id) {
    conversationUnreads.classList.add('unread-message-count');
    const unreadMessages = parseInt(conversationUnreads.dataset.count);
    conversationUnreads.setAttribute('data-count', unreadMessages + 1);
    conversationUnreads.innerHTML = conversationUnreads.getAttribute('data-count');
  }
}

const addLastMessage = (parsedData) => {
  const lastMessageContainer = document.querySelector(`#conversation_last_${parsedData.conversation_id}`);
  const lastMessageTimeContainer = document.querySelector(`#conversation_last_message_time_${parsedData.conversation_id}`);
  if (lastMessageContainer) lastMessageContainer.innerHTML = parsedData.message_content;
  if (lastMessageTimeContainer) lastMessageTimeContainer.innerHTML = parsedData.time;
  const conversation = document.querySelector(`#conversation_${parsedData.conversation_id}`);
  if (conversation) {
    const html = conversation.outerHTML;
    conversation.remove();
    const conversationContainer = document.querySelector('#conversation_container');
    conversationContainer.insertAdjacentHTML('afterbegin', html);
    Array.from(conversationContainer.children).forEach((child) => {
      if (child.previousElementSibling) {
        child.previousElementSibling.classList.contains('chat-blue') ? child.classList.remove('chat-blue') : child.classList.add('chat-blue')
      }
    })
  }
  const noConversations = document.querySelector('#no_conversation');
  if (noConversations) noConversations.remove();
}

const insertMessage = (messagesContainer, parsedData) => {
  const currentDate = document.querySelector(`[data-value="${parsedData.date}"]`);
  if (!currentDate) {
    messagesContainer.insertAdjacentHTML('beforeend', parsedData.date_partial);
  }
  messagesContainer.insertAdjacentHTML('beforeend', parsedData.message);
  const lastMessage = messagesContainer.lastElementChild;
  if (currentUser() != parsedData.user_id) {
    lastMessage.classList.remove('flex-row-reverse');
    lastMessage.querySelector('.message--content').classList.remove('message--owner');
    lastMessage.insertAdjacentHTML('afterbegin', parsedData.message_avatar);
  }
  const newConversation = document.querySelector('#new_conversation_message');
  if (newConversation) newConversation.remove();
};

const tableMessageNotification = (parsedData) => {
  const tableConversationUnreads = document.getElementById(`table_conversation_unread_${parsedData.conversation_id}`);
  const sideNav = document.getElementById('sideNav');
  if (tableConversationUnreads && sideNav && currentUser() != parsedData.user_id) {
    if (sideNav.classList.contains('d-none')) tableConversationUnreads.classList.remove('d-none');
    document.getElementById('chat-tab').click();
  }
};

export {
  scrollToBottom,
  insertStrike,
  addUnreadMessage,
  insertMessage,
  addLastMessage,
  tableMessageNotification
}
