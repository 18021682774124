import { Controller } from 'stimulus';
import { initZxcvbn } from './../packs/components/init_zxcvbn';
import { initSelect2 } from './../packs/components/init_select2';

export default class extends Controller {
  static targets = [ 'passwordStrength', 'select2' ];

  connect() {
    this.select2Targets.forEach(element => initSelect2(element, {
      width: '100%',
      height: '100%',
      minimumInputLength: 1,
      placeholder: element.dataset.placeholder,
      language: {
        inputTooShort: function () {
          return "Start typing...";
        }
      },
      ajax: {
        url: `/api/v1/${element.dataset.endpoint}`,
        dataType: 'json',
        type: 'GET',
        data: function(params) {
          var query = {
            search: params.term
          }
          return query
        },
        processResults: function(data) {
          return {
            results: data
          }
        },
        error: function(_jqXHR, _status, _error) {
          return { results: [] };
        }
      }
    }));
  }

  updatePasswordStrength(event) {
    const strength = initZxcvbn(event.currentTarget.value);
    this.passwordStrengthTarget.value = strength.score;
  }
}
