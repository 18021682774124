import { Controller } from 'stimulus';
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = [ 'input', 'image', 'cropX', 'cropY', 'cropWidth', 'save', 'cancel',, 'editAvatar',
  'cropHeight', 'coverCropX', 'coverCropY', 'coverCropWidth', 'coverCropHeight', 'dropdownBtn' ];

  changed() {
    $('#cropping-modal').modal('show');
    if (this.inputTarget.files && this.inputTarget.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.initializeCropper(event);
      }
      reader.readAsDataURL(this.inputTarget.files[0]);
    }
  }

  resize(event) {
    event.preventDefault();
    this.initializeCropper();
  }

  cancel() {
    this.cropper.destroy();
    if (this.hasSaveTarget) this.saveTarget.classList.add('d-none');
    if (this.hasDropdownBtnTarget) this.dropdownBtnTarget.classList.remove('d-none');
    if (this.hasCancelTarget) this.cancelTarget.classList.add('d-none');
    this.imageTarget.src = this.imageTarget.dataset.src;
  }

  initializeCropper(event = null) {
    if (event) {
      this.imageTarget.src = event.currentTarget.result;
    } else {
      this.imageTarget.src = this.imageTarget.dataset.uncropped.split('?')[0];
    }
    let _this = this;
    if (this.hasSaveTarget) this.saveTarget.classList.remove('d-none');
    if (this.hasDropdownBtnTarget) this.dropdownBtnTarget.classList.add('d-none');
    if (this.hasCancelTarget) this.cancelTarget.classList.remove('d-none');
    if (this.hasEditAvatarTarget) this.editAvatarTarget.classList.add('d-none');
    this.cropper = new Cropper(this.imageTarget, {
      viewMode: 3,
      autoCropArea: 1,
      minCropBoxWidth: 1,
      minCropBoxHeight: 1,
      cropBoxResizable: false,
      minCanvasWidth: 1,
      minCanvasHeight: 1,
      minContainerWidth: _this.hasCropXTarget ? 150 : '100%',
      minContainerHeight: _this.hasCropXTarget ? 150 : '100%',
      crop(event) {
        if (_this.hasCropXTarget) {
          _this.cropXTarget.value = event.detail.x;
          _this.cropYTarget.value = event.detail.y;
          _this.cropWidthTarget.value = event.detail.width;
          _this.cropHeightTarget.value = event.detail.height;
        } else {
          _this.coverCropXTarget.value = event.detail.x;
          _this.coverCropYTarget.value = event.detail.y;
          _this.coverCropWidthTarget.value = event.detail.width;
          _this.coverCropHeightTarget.value = event.detail.height;
        }
      }
    });
  }

  closeModal() {
    $('#cropping-modal').modal('hide');
  }
}
