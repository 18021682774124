import { Controller } from 'stimulus';
import Rails from '@rails/ujs'
import { subtract } from 'lodash';

export default class extends Controller {
  static targets = [ 'form', 'background', 'label', 'input', 'submit' ];

  update(event) {
    const xhr = JSON.parse(event.detail[2].response);
    this.inputTarget.disabled = false;
    this.backgroundTarget.style.backgroundImage = `linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url('${xhr.background}')`;
    this.formTarget.reset();
    this.disable();
  }

  enable() {
    if (this.inputTarget.value) this.submitTarget.removeAttribute('disabled');
  }

  disable() {
    this.submitTarget.setAttribute('disabled', true);
  }

  error() {
    this.labelTarget.innerText = "Something went wrong 😕";
    this.formTarget.reset();
    this.disable();
  }
}
