import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'ctas', 'count' ];

  updateFriendshipStatus(event) {
    const xhr = event.detail[2];
    const response = JSON.parse(xhr.response);
    this.ctasTarget.outerHTML = response.ctas;
    if (response.count) this.countTarget.innerHTML = `${response.count} ${response.count === 1 ? 'friend' : 'friends'}`;
  }
}
