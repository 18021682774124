import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'connectedCta', 'onlineUsers', 'offlineUsers' ];

  toggleConnectedUsers(event) {
    if(event.target.classList.contains('text-primary')) return;
    this.connectedCtaTargets.forEach(target => target.classList.remove('text-primary'));
    event.target.classList.add('text-primary');
    this.onlineUsersTarget.classList.toggle('d-none');
    this.offlineUsersTarget.classList.toggle('d-none');
  }
}
