import { Controller } from 'stimulus';
import _ from 'lodash';

export default class extends Controller {
  static targets = [ 'input', 'results', 'suggestion' ];

  initialize() {
    this.getSuggestions = _.debounce(this.getSuggestions, 500);
  }

  connect() {
    this.element.addEventListener('submit', (event) => {
      if (this.selectedSuggestion) {
        event.preventDefault();
        window.location.href = this.selectedSuggestion.dataset.url;
      }
    });
  }

  suggest(event) {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      this.access(event);
    } else if (event.code === 'Escape') {
      this.resultsTarget.innerHTML = "";
      this.selectedSuggestion = undefined;
      this.resultsTarget.classList.add('d-none');
    } else {
      this.getSuggestions();
    }
  }

  getSuggestions() {
    const query = this.inputTarget.value;
    fetch(`${this.data.get('url')}?search=${query}`)
      .then(response => response.json())
      .then((data) => {
        this.showSuggestions(data);
      });
  }

  showSuggestions(data) {
    this.resultsTarget.innerHTML = "";
    if (data.length === 0 && this.inputTarget.value !== "") {
      this.resultsTarget.classList.remove('d-none');
      this.resultsTarget.innerHTML = "No results found";
    } else if (this.inputTarget.value === "") {
      this.resultsTarget.classList.add('d-none');
    } else {
      this.resultsTarget.classList.remove('d-none');
      this.resultsTarget.innerHTML = data.suggestions;
    }
  }

  updateSelectionOnClick(event) {
    this.selectedSuggestion = event.currentTarget;
    this.inputTarget.focus();
  }

  access(event) {
    if (event.code === 'ArrowDown') {
      if (this.selectedSuggestion && this.selectedSuggestion.nextElementSibling) {
        this.selectedSuggestion = this.selectedSuggestion.nextElementSibling;
      } else {
        this.selectedSuggestion = this.suggestionTargets[0];
      }
    } else if (event.code === 'ArrowUp') {
      if (this.selectedSuggestion && this.selectedSuggestion.previousElementSibling) {
        this.selectedSuggestion = this.selectedSuggestion.previousElementSibling;
      } else {
        this.selectedSuggestion = this.suggestionTargets[this.suggestionTargets.length - 1];
      }
    }

    if (!this.selectedSuggestion) return;

    const position = Array.from(this.suggestionTargets).indexOf(this.selectedSuggestion);
    const suggestionHeight = this.selectedSuggestion.clientHeight;
    const scrollTop = this.resultsTarget.scrollTop;
    const viewPort = scrollTop + this.resultsTarget.clientHeight;
    const offset = suggestionHeight * position;
    if (offset < scrollTop || (offset + suggestionHeight) > viewPort) {
      this.resultsTarget.scroll({ top: offset });
    }
  }

  get selectedSuggestion() {
    return this.suggestionTargets.find(suggestion => suggestion.dataset.selected === "true");
  }

  set selectedSuggestion(newSuggestion) {
    if (this.selectedSuggestion) {
      this.selectedSuggestion.classList.remove('suggestion--selected');
      this.selectedSuggestion.dataset.selected = false;
    }
    if (!newSuggestion) return;
    newSuggestion.dataset.selected = true;
    newSuggestion.classList.add('suggestion--selected');
    this.inputTarget.value = newSuggestion.innerText;
  }
}
