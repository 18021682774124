import consumer from "./consumer";
import { sendNotification } from './../packs/helpers/web_notifications';

document.addEventListener('turbolinks:load', () => {
  consumer.subscriptions.create({ channel: "NotificationsChannel" }, {
    received(data) {
      const counters = document.querySelectorAll('.notifications-count');
      const messagesContainer = document.querySelector('#messages');
      if (!messagesContainer){
        counters.forEach((counter) => {
          if(counter.firstElementChild){
            counter.firstElementChild.innerHTML = parseInt(counter.firstElementChild.innerHTML) + 1;
            counter.classList.remove('d-none');
          }
        })
      }
      const notificationsContainer = document.querySelector('.notifications');
      if (!messagesContainer){
        sendNotification('You have a new notification');
      }
      if (notificationsContainer) {
        notificationsContainer.insertAdjacentHTML('afterbegin', data.notification_html);
        const notificationsRead = document.querySelector('.notifications-read');
        notificationsRead.innerHTML = data.notifications_read;
      }
    }
  });
});
