const requestPermission = async () => {
  if("Notification" in window) {
    await Notification.requestPermission();
  }
};

const sendNotification = (body) => {
  if (document.hidden && Notification.permission == 'granted') {
    new Notification('The Virtual Library', { body });
  }
};

export { requestPermission, sendNotification };

