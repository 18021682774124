import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = [ 'swal', 'action' ];

  fireSwal() {
    Swal.fire({
      title: this.swalTarget.dataset.title,
      text: this.swalTarget.dataset.text,
      icon: this.swalTarget.dataset.icon,
      confirmButtonText: this.swalTarget.dataset.confirmButtonText,
      confirmButtonColor: this.swalTarget.dataset.confirmButtonColor,
      showCancelButton: this.swalTarget.dataset.showCancelButton === 'true'
    }).then((result) => {
      if (result.value && this.hasActionTarget) {
        this.actionTarget.click();
      }
    });
  }
}
