import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import LazyLoader from 'stimulus-lazy-loader';

const application = Application.start();
application.register("lazy-loader", LazyLoader);

document.addEventListener('turbolinks:load', () => {
  const context = require.context("controllers", true, /_controller\.js$/)
  application.load(definitionsFromContext(context))
});

document.addEventListener('turbolinks:before-cache', () => {
  application.controllers.forEach((controller) => {
    if(typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});
