const inviteOccupant = () => {
  const occupantsContainer = document.getElementById('occupants');
  const wrapper = document.querySelector('.wrapper');
  const form = document.getElementById('new_occupant');
  const submitButton = document.getElementById('submitButton');
  if (submitButton && occupantsContainer) {
    submitButton.addEventListener('click', (event) => {
      event.preventDefault();
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let json = JSON.parse(xhr.responseText);
          occupantsContainer.innerHTML = json.content;
          wrapper.insertAdjacentHTML('beforebegin', json.flash);
          inviteOccupant();
          removeOccupant();
        }
      }
      xhr.open('POST', `/rooms/${occupantsContainer.dataset.table}/invite`);
      xhr.send(new FormData(form));
    });
  }
};

const removeOccupant = () => {
  const occupantsContainer = document.getElementById('occupants');
  const removeButtons = document.querySelectorAll('.removeButton');
  if (removeButtons && occupantsContainer) {
    removeButtons.forEach((removeButton) => {
      removeButton.addEventListener('click', (event) => {
        event.preventDefault();
        const payload = new URLSearchParams();
          payload.append('_method', 'DELETE');
          payload.append('authenticity_token', document.querySelector('meta[name="csrf-token"]').content);
          fetch(`/occupants/${removeButton.dataset.occupant}/remove`, {
            method: 'POST',
            body: payload
          })
          .then(response => {
            if (response.ok) return response.json();
          })
          .then((json) => {
            occupantsContainer.innerHTML = json.content;
            inviteOccupant();
            removeOccupant();
          });
      });
    });
  }
};

export { inviteOccupant, removeOccupant };
