import consumer from "./consumer";

  document.addEventListener('turbolinks:load', () => {
    consumer.subscriptions.create({ channel: "RemindersChannel" }, {
      received(data) {
        const parsedData = JSON.parse(data);
        const remindersContainer = document.querySelector('.toasts');
        remindersContainer.insertAdjacentHTML("afterbegin", parsedData.reminder);
        $(`#${parsedData.id}`).toast('show');
        const audio = document.getElementById('reminder-audio');
        if (audio) { audio.play() };
     }
   });

});
