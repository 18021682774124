import autosize from 'autosize';

const initAutosize = (element) => {
  autosize(element);
}

const updateSize = (element) => {
  autosize.update(element);
}

export { initAutosize, updateSize };
