import { Controller } from 'stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = [ 'input' ];
  connect() {
    intlTelInput(this.inputTarget, {
      preferredCountries: ['gb', 'fr', 'lb', 'us', 'sp', 'it']
    });
    this.inputTarget.value = `+${document.querySelector('.iti__active').getAttribute('data-dial-code')}`;
    const countries = document.querySelectorAll('.iti__country');
    countries.forEach((country) => {
      country.addEventListener('click', () => {
        this.inputTarget.value = `+${country.getAttribute('data-dial-code')}`;
      })
    })
  }
}
