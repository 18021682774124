import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'bubbles', 'bubbles2', 'homebubbles' ];

  scrollbubble(event) {
    const move = document.body.scrollTop - document.documentElement.scrollTop ;
    if (document.querySelector('.about-container')) {
      this.bubblesTarget.style.top = move + "px";
      this.bubbles2Target.style.top = move + "px";
    } else {
      this.homebubblesTarget.style.top = move + "px";
    }
  }
}
