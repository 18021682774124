import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "container" ]

  clickLike(event) {
    const xhr = event.detail[2];
    const response = xhr.response;
    this.containerTarget.outerHTML = response;
  }
}
