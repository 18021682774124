import { Controller } from 'stimulus';
import { initAutosize, updateSize } from './../packs/components/init_autosize';
import { initSelect2 } from './../packs/components/init_select2';
import Rails from "@rails/ujs";
import { broadcastMessage } from './../channels/conversation_channel';

export default class extends Controller {
  static targets = [ 'messagesContainer', 'formWrapper', 'loader', 'strike', 'count',
                     'content', 'uploader', 'uploadedList', 'clearAttachedFiles',
                     'userSelect', 'conversationContainer', 'conversation', 'messageForm',
                     'noConversations', 'conversationsCount' ];

  connect() {
    if (!this.hasMessagesContainerTarget) return;
    this.scrollToBottom();
    this.messagesContainerTarget.addEventListener('click', () => {
      this.updateLastReadAt()
    });
    this.paginator();
    initAutosize(this.contentTarget);
  }

  updateOnSubmission(event) {
    if (event.keyCode === 13) this.updateLastReadAt();
  }

  scrollToBottom() {
    this.messagesContainerTarget.scroll({ top: this.messagesContainerTarget.scrollHeight });
  }

  insertMessage(event) {
    const xhr = event.detail[2];
    const response = JSON.parse(xhr.response);
    this.insertAndfocus(response);
  }

  insertAndfocus(data) {
    const currentConversationId = window.location.pathname.match(/\d+/)[0];
    const currentConversation = this.conversationTargets.find(conv => conv.id === `conversation_${currentConversationId}`);
    this.formWrapperTarget.outerHTML = data.form;
    if (this.hasNoConversationsTarget) this.noConversationsTarget.remove();
    if (this.hasConversationContainerTarget && data.conversation && !currentConversation) {
      this.conversationContainerTarget.insertAdjacentHTML('afterbegin', data.conversation);
      if (this.hasConversationsCountTarget) this.updateConversationCount()
    }
    this.hasContentTarget && this.contentTarget.focus();
  }

  updateConversationCount() {
    const count = parseInt(this.conversationsCountTarget.dataset.count, 10)
    this.conversationsCountTarget.innerText = `Conversations (${count + 1})`
  }

  expandInput(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      Rails.ajax({
        type: 'POST',
        url: this.messageFormTarget.action,
        data: new FormData(this.messageFormTarget),
        dataType: 'json',
        success: (data) => {
          this.insertAndfocus(data);
        }
      });
    } else {
      updateSize(this.contentTarget);
    }
  }

  switchConversation(event) {
    this.loaderTarget.classList.remove('d-none');
    this.messagesContainerTarget.innerHTML = '';
    const conversation = event.currentTarget;
    const conversationId = conversation.dataset.conversationId;
    this.conversationTargets.forEach(conversation => conversation.classList.remove('chat-active'))
    conversation.classList.add('chat-active');
    const url = `/api/v1/conversations/${conversationId}`;
    this.fetchMessages(url, conversationId, conversation);
  }

  updateLastReadAt() {
    const tableString = this.messagesContainerTarget.dataset.table ? '?table=true' : '';
    fetch(`/api/v1/participants/${this.data.get('participant-id')}${tableString}`, { method: "PUT" })
      .then(response => response.json())
      .then((data) => {
        if (this.hasStrikeTarget) this.strikeTarget.remove();
        const count = this.countTargets.find(target => target.id === `conversation_unread_${this.messagesContainerTarget.dataset.id}`);
        const notificationCount = document.querySelector('.notifications-count');
        const unreadTableMessages = document.querySelector('.unread-table-messages');
        if (unreadTableMessages) unreadTableMessages.classList.add('d-none');
        if (count) {
          count.setAttribute('data-count', 0)
          count.innerHTML = '';
          count.classList.remove('unread-message-count');
        }
        if (notificationCount) {
          notificationCount.innerHTML = data.unread_notification_count;
          if (data.unread_notification_count === 0) notificationCount.classList.add('d-none');
        }
      });
  }

  fetchMessages(url, conversationId, conversation) {
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        this.loaderTarget.classList.add('d-none');
        this.formWrapperTarget.outerHTML = data.form.content;
        initAutosize(this.contentTarget);
        this.messagesContainerTarget.setAttribute('data-id', conversationId);
        this.messagesContainerTarget.setAttribute('data-max', data.max);
        this.messagesContainerTarget.setAttribute('data-page', 1);
        this.messagesContainerTarget.innerHTML = data.messages;
        this.messagesContainerTarget.insertAdjacentHTML('afterbegin', data.header);
        this.scrollToBottom();
        history.pushState({
          id: 'conversation'
        }, 'KROWL - Conversation', `/conversations/${conversationId}`);
        const participantId = conversation.dataset.participantId;
        this.data.set('participant-id', participantId);
        broadcastMessage('ConversationChannel');
        broadcastMessage('TableConversationChannel');
      });
  }

  paginator() {
    let fetching = false;
    this.messagesContainerTarget.addEventListener('scroll', () => {
      const currentPage = +this.messagesContainerTarget.dataset.page;
      const maxPage = +this.messagesContainerTarget.dataset.max;
      if (this.messagesContainerTarget.scrollTop < 100 && !fetching && currentPage < maxPage) {
        this.loaderTarget.classList.remove('d-none');
        const currentScrollHeight = this.messagesContainerTarget.scrollHeight;
        const conversationId = this.messagesContainerTarget.dataset.id;
        const tableString = this.messagesContainerTarget.dataset.table ? '&table=true' : '';
        const nextPage = +this.messagesContainerTarget.dataset.page + 1;
        fetching = true;
        fetch(`/api/v1/conversations/${conversationId}/messages?page=${nextPage}${tableString}`)
          .then(response => response.json())
          .then((data) => {
            if (data.messages) {
              this.loaderTarget.classList.add('d-none');
              const lastDate = document.querySelector(`[data-value="${data.last_message_date}"]`);
              if (lastDate) {
                lastDate.parentElement.remove();
              }
              this.messagesContainerTarget.insertAdjacentHTML('afterbegin', data.messages);
              const newScrollHeight = this.messagesContainerTarget.scrollHeight;
              this.messagesContainerTarget.scroll({ top: newScrollHeight - currentScrollHeight });
              this.messagesContainerTarget.setAttribute('data-page', nextPage);
              fetching = false;
            }
          });
      }
    })
  }

  previewFiles() {
    if (this.uploaderTarget.files && this.uploaderTarget.files.length > 0) {
      const files = Array.from(this.uploaderTarget.files);
      this.uploadedListTarget.innerHTML = '';
      files.forEach((file) => {
        this.uploadedListTarget.insertAdjacentHTML('beforeend', `
          <span class="mr-2" data-name="${file.name}" data-conversations-target="file">
            <span class="mr-1">${file.name}</span>
          </span>
        `);
      });
      this.clearAttachedFilesTarget.classList.remove('d-none');
    }
  }

  removeAttachedFiles() {
    this.uploaderTarget.value = '';
    this.uploadedListTarget.innerHTML = '';
    this.clearAttachedFilesTarget.classList.add('d-none');
  }
}
