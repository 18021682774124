import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'header', 'body' ];

  toggle() {
    if (!this.bodyTarget.classList.contains('collapsing')) {
      this.headerTarget.classList.toggle('active-header');
      this.element.classList.toggle('shadow-sm');
      this.element.classList.toggle('shadow');
      this.element.classList.toggle('active');
    }
  }

  static preventPropagation(event) {
    event.stopPropagation();
  }

  preventToggle(event) {
    this.constructor.preventPropagation(event);
  }
}
