import consumer from './consumer';
import { currentUser } from './../packs/helpers/current_user_helper';

document.addEventListener('turbolinks:load', () => {
  consumer.subscriptions.create({ channel: 'OnlineChannel' }, {
    received(data) {
      if (data.id !== currentUser()) {
        const onlineContainer = document.querySelector('#online-users');
        const offlineContainer = document.querySelector('#offline-users');
        const emptyOnline = document.querySelector('#no-one-online');
        const emptyOffline = document.querySelector('#no-one-offline');
        const conversationContainer = document.querySelector('#conversation_container');
        if (onlineContainer && offlineContainer) {
          const onlineUser = onlineContainer.querySelector(`[data-id="${data.id}"]`);
          const offlineUser = offlineContainer.querySelector(`[data-id="${data.id}"]`);
          if (data.status === 'online') {
            if (!onlineUser) onlineContainer.insertAdjacentHTML('beforeend', data.html);
            if (offlineUser) offlineUser.remove();
            if (emptyOnline) emptyOnline.remove();
            if (offlineContainer.childElementCount === 0 && !emptyOffline) offlineContainer.insertAdjacentHTML('afterbegin', `<em class="text-muted pl-3" id="no-one-offline">There is no one offline at the moment</em>`);

          } else if (data.status === 'offline') {
            if (!offlineUser) offlineContainer.insertAdjacentHTML('beforeend', data.html);
            if (onlineUser) onlineUser.remove();
            if (emptyOffline) emptyOffline.remove();
            if (onlineContainer.childElementCount === 0 && !emptyOnline) onlineContainer.insertAdjacentHTML('afterbegin', `<em class="text-muted pl-3" id="no-one-online">There is no one online at the moment</em>`)
          }
        } else if (conversationContainer) {
          const users = conversationContainer.querySelectorAll(`[data-id="${data.id}"]`);
          users.forEach((user) => {
            user.classList.toggle('offline', user.dataset.status === 'true');
            user.classList.toggle('far', user.dataset.status === 'true');
            user.classList.toggle('fas', user.dataset.status === 'false');
            user.classList.toggle('online', user.dataset.status === 'false');
            user.setAttribute('data-status', user.dataset.status !== 'true');
          })
        }
      }
    }
  });
});
