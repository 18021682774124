import consumer from "./consumer";
import { currentUser } from './../packs/helpers/current_user_helper';
import { inviteOccupant, removeOccupant } from './../packs/components/init_occupants';
import Swal from 'sweetalert2';

const updateOccupants = () => {
  const occupantsContainer = document.getElementById('occupants');
  if (occupantsContainer) {
    const tableId = occupantsContainer.dataset.table;
    consumer.subscriptions.create({ channel: 'TableChannel', id: tableId }, {
      received(data) {
        if (data.destroy && data.user != currentUser()) {
          Swal.fire({
            title: 'Table deleted',
            text: 'The table has been deleted by the host',
            icon: 'info'
          }).then((result) => {
            window.location.replace('/workspaces');
          });
        } else if (data.remove && data.occupant == currentUser() && data.user != currentUser()) {
          Swal.fire({
            title: 'Table no longer available',
            text: 'You have been removed by the host',
            icon: 'info'
          }).then((result) => {
            window.location.replace('/workspaces');
          });
        } else if (data.user != currentUser()) {
          fetch(`/rooms/${tableId}/occupants`)
          .then((response) => response.json())
          .then((json) => {
            occupantsContainer.innerHTML = json.content;
            inviteOccupant();
            removeOccupant();
          });
        }
      }
    });
  }
};

document.addEventListener('turbolinks:load', () => {
  updateOccupants();
});
