import { Controller } from 'stimulus';
import { initSelect2 } from './../packs/components/init_select2';

export default class extends Controller {
  static targets = [  'select' ];

  connect() {
    initSelect2(this.selectTarget, {
      width: '100%',
      minimumInputLength: 3,
      placeholder: 'Find friends...',
      language: {
        inputTooShort: function () {
          return "Start typing...";
        }
      },
      ajax: {
        url: this.data.get('url'),
        dataType: 'json',
        type: 'GET',
        data: function(params) {
          var query = {
            search: params.term
          }
          return query
        },
        processResults: function(data) {
          return {
            results: data
          }
        },
        error: function(_jqXHR, _status, _error) {
          return { results: [] };
        }
      }
    });
  }
}
