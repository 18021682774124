import { Controller } from 'stimulus';

export default class extends Controller {

  updateNotification(event) {
    const xhr = event.detail[2];
    const response = JSON.parse(xhr.response);
    this.element.outerHTML = response.notification;
    const notificationCount = document.querySelector('.notifications-count');
    notificationCount.innerText = response.notification_count;
    notificationCount.classList.toggle('d-none', response.notification_count === 0)
    const notificationRead = document.querySelector('.notifications-read');
    notificationRead.innerHTML = response.read_all_link
  }
}
