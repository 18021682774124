import { Controller } from 'stimulus';
import $ from 'jquery'
export default class extends Controller {
  connect() {
    $(window).on("load resize scroll", function() {
      $(".bg-static").each(function() {
        var section = $(window).scrollTop();
        var windowTop = section + 95
        var elementTop = $(this).offset().top;
        var leftPosition = windowTop - elementTop;
        if (leftPosition <= 0) {
          leftPosition = leftPosition
        } else {
          leftPosition = 0
        }
          $(this)
            .find(".bg-move")
            .css({ left: leftPosition });
      });
    });
  }
}
